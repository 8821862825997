import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import ProjectCard from "../components/projectCard"
import Contact from "../components/contact"
import Modal from "../components/modal"

import Fade from "react-reveal/Fade"
import { CSSTransition } from "react-transition-group"
import { StaticQuery, graphql } from "gatsby"

const services = ["DEVELOPER", "DESIGNER", "CREATOR", "ENGINEER", "ADAPTER"]

const IndexPage = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(null);
  const [animatedTag, setAnimatedTag] = useState({
    index: 0,
    fadeOut: false,
  })
  const tagIntervalTime = 10000
  const tagRef = useRef(animatedTag)
  tagRef.current = animatedTag

  const {
    tagline,
    heroTitle,
    contactTitle,
    contactDesc,
    portfolioCards,
  } = data.site.siteMetadata

  const tagSwap = () => {
    const animatedTag = tagRef.current
    setAnimatedTag({ ...animatedTag, fadeOut: true })

    setTimeout(() => {
      const newIndex =
        animatedTag.index < services.length - 1 ? animatedTag.index + 1 : 0
      setAnimatedTag({ index: newIndex, fadeOut: false })
    }, 1000)
  }

  const runTagAnimation = () => {
    setTimeout(() => {
      tagSwap()
      runTagAnimation()
    }, tagIntervalTime)
  }

  const openModal = (modalContent) => {
    setModalOpen(modalContent);
  }

  const closeModal = () => {
    setModalOpen(null);
  }

  useEffect(() => {
    runTagAnimation()
  }, [])

  return (
    <>
      <Modal modalOpen={modalOpen} closeModal={closeModal} />
      <Layout>
        <section className="home hero wrapper">
          <h2 className="hero__logo nav__logo">Jon Mak</h2>
          <div className="hero__content">
            <h2>{heroTitle}</h2>
          </div>
        </section>
        <section id="about" className="about wrapper">
          <h2 className="section-header">A bit about me...</h2>
          <div className="about__content">
            <div className="about__desc">
              <p>
                I have a background in Business and IT, but my passion and itch to
                deliver visual products moved me into the space of developing
                software. My fascination with understanding and solving complex
                problems coupled with my interest in design drives me to build
                impactful products.
              </p>
              <p>
                I believe at the center of every good software product lies clean,
                scalable and robust code along with an exceptional user
                experience. These are the elements I strive to implement whenever
                I embark on a project. Here's a showcase of the most successful projects I've worked on thus far
                and I'm excited to see what I'll build in the next.
              </p>
              <p>
                When I'm not coding, you'll find me investing, going for a jog,
                playing pickup basketball with friends or learning how to perfect my golf
                swing!
              </p>
            </div>
            <div className="about__animated">
              <div
                className={
                  "about__animated-bar " +
                  (animatedTag.fadeOut ? "animate-slide" : "")
                }
              />
              <CSSTransition
                in={!animatedTag.fadeOut}
                timeout={400}
                classNames="animated-tag"
                unmountOnExit
              >
                <h1>{services[animatedTag.index]}</h1>
              </CSSTransition>
            </div>
          </div>
        </section>
        <section id="work" className="work wrapper">
          <div className="work__title-container">
            <h2>PROJECTS</h2>
          </div>
          <div>
            {portfolioCards.map((card, index) => {
              const isEven = (index + 1) % 2 === 0
              const distance = isEven ? "250px" : "100px"
              return (
                <Fade key={"Project Card - " + index} top distance={distance}>
                  <ProjectCard data={card} openModal={openModal} />
                </Fade>
              )
            })}
          </div>
        </section>
        <section id="contact" className="contact wrapper">
          <h2 className="section-header">{contactTitle}</h2>
          <div className="contact__desc">
            <p>{contactDesc}</p>
          </div>
          <Fade bottom distance="100px">
            <Contact />
          </Fade>
        </section>
      </Layout>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query SiteMetadataQuery {
        site {
          siteMetadata {
            tagline
            heroTitle
            contactTitle
            contactDesc
            portfolioCards {
              imgSrc
              imgAlt
              imgSrc2
              imgAlt2
              colorScheme
              title
              organization
              link
              modalContent
            }
          }
        }
      }
    `}
    render={data => <IndexPage data={data} {...props} />}
  />
)
