import React, { useState } from "react"

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    project: "",
  })

  const { name, email, project } = formData

  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = async e => {
    e.preventDefault()
  }

  return (
    <form
      name="contact-form"
      method="POST"
      className="contact__form"
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <input type="hidden" name="form-name" value="contact-form" />
      <input
        className="contact__field"
        type="text"
        placeholder="Enter your name..."
        name="name"
        value={name}
        onChange={e => handleChange(e)}
        required
      />
      <input
        className="contact__field"
        type="email"
        placeholder="Enter your email..."
        name="email"
        value={email}
        onChange={e => handleChange(e)}
        required
      />
      <input
        className="contact__field"
        type="text"
        placeholder="Briefly describe your project"
        name="project"
        value={project}
        onChange={e => handleChange(e)}
        required
      />
      <div className="contact__send-wrapper">
        <div className="contact__send">
          <img
            className="contact__send-icon"
            src={require("../assets/right-arrow.png")}
            alt="right-arrow"
          />
          <input type="submit" value="Send" />
        </div>
      </div>
    </form>
  )
}

export default Contact
