import React from "react";
import OrdersAdminFeatureMP4 from '../../assets/force/force-orders-admin-vid.mp4';

const ModalForceOrdersAdminContent = () => (
  <>
    <h2 className="modal-main__title">Force - Orders Admin Tool</h2>
    <div className="modal-main__content">
      <div className="modal-main__project-title">
        <h3>Order Fulfillment & Approvals</h3>
      </div>
      <video autoPlay="true" loop="true" style={{ width: "100%" }}>
        <source src={OrdersAdminFeatureMP4} type="video/mp4" />
      </video>
      <div className="modal-main__desc">
        <p>
          Order and inventory fufillment team at Mojio struggled to keep track of status on device orders that were pending review or shipped.   
          I was tasked with spearheading the frontend development of an internal in house app that helps with providing transparency on the order fulfillment lifecycle. This was the birth of the Force Orders Admin tool.
        </p>
        <p>
          This new application equips the Mojio team with the ability to view orders by status with a pagination view. Orders are stored in global state when loaded. 
          I owned the full design and development of the project using Figma as my design tool and React, Redux, Typescript, Sass, Charka UI to build out the Frontend. 
        </p>
        <p>
          The user can drill into each order to view additional details on that order. 
          A key requirement in the project was to provide the ability to <b>Approve</b>, <b>Cancel</b> and <b>Edit</b> orders in one convenient interface. 
          What used to take on average 2-3 full days to approve orders, now takes mere seconds with a click of a button. 
        </p>
      </div>
    </div>
  </>
);

export default ModalForceOrdersAdminContent;