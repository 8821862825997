import React from "react"
import Image from "./image"

const ProjectCard = ({
  data: { imgSrc, imgAlt, imgSrc2, imgAlt2, colorScheme, title, organization, link, modalContent },
  openModal
}) => {
  const isUnderConstruction = imgSrc === ""
  const mobileImages = (imgSrc !== null && imgSrc2 !== null);

  const mainDisplay = () => {
    if (isUnderConstruction) {
      return (
        <h3 className="project-card__under-construction">
          UNDER
          <br />
          CONSTRUCTION
        </h3>
      )
    }
    if (mobileImages) {
      return (
        <div className="project-card__mobile-container">
          <div className="project-card__img-display" style={{width: '27%'}}>
            <Image imgSrc={imgSrc} imgAlt={imgAlt} />
          </div>
          <div className="project-card__img-display" style={{width: '27%'}}>
            <Image imgSrc={imgSrc2} imgAlt={imgAlt2} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="project-card__img-display" style={{width: '70%'}}>
          <Image imgSrc={imgSrc} imgAlt={imgAlt} />
        </div>
      )
    }
  }

  return (
    <div className="project-card">
      {modalContent ? (
        // Launch Modal layover
        <div
          onClick={() => openModal(modalContent)}
          className={"project-card__img-wrapper" + (isUnderConstruction ? " disabled" : "")}
          style={{"background": colorScheme}}
        >
          {mainDisplay()}
        </div>
      ) : (
        // Link to Project
        <a
          className={"project-card__img-wrapper" + (isUnderConstruction ? " disabled" : "")}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{"background": colorScheme}}
        >
          {mainDisplay()}
        </a>
      )}
      <h3>{title}</h3>
      <p>{organization}</p>
    </div>
  )
}

export default ProjectCard
