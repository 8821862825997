import React from "react";
import Fade from "react-reveal/Fade";
import ModalForceContent from "./modal-content/modalContentForce";
import ModalForceOrdersAdminContent from "./modal-content/modalContentForceOrdersAdmin";

const Modal = ({ modalOpen, closeModal }) => {  
  if (typeof window !== "undefined" && window.document) {
    if (!modalOpen) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }

  if (!modalOpen) return null;

  const ModalContent = () => {
    switch(modalOpen) {
      case 'force':
        return <ModalForceContent />
      case 'force-orders-admin':
        return <ModalForceOrdersAdminContent />
    }
  }

  return (
    <div className="modal">
      <Fade key="modal-screen" bottom distance="50px">
        <div className="modal-main">
          <img 
            className="close-btn"
            src={require("../assets/close-icon.png")}
            alt="close-modal-icon"
            onClick={closeModal}
          />
          {ModalContent()}
        </div>
      </Fade>
    </div>
  )
}

export default Modal
